$color_1: #fff;

.cta-sec {
    height: 12em;
    padding: 1em 1em;
    background: #ffc828;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(medium down) {
        font-size: 0.7em;
        padding: 2em 1em;
    }
}

.cta-btn {
    display: flex;
    text-align: center;
    justify-content: center;
    background: #4472c4;
    border-radius: 0.5em;
    padding: 1.5em 3.5em;
    color: #fff;
    font-size: 1.5em;
    font-weight: 800;

    @include breakpoint(medium down) {
        padding: 0.5em 0.5em;
        font-size: 2em;
    }

    &:hover {
        text-decoration: none;
        color: $color_1;
    }
}
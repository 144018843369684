$table-bg-color_1: #ffc000;
$table-bg-color_2: #4472c4;
$table-bg-color_3: #0000008c;

.yellow-box {
    padding: 4em 1em;
    background: $table-bg-color_2;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include breakpoint(medium down) {
        font-size: 0.7em;
        padding: 2em 1em;
    }
}

.yellow-box-container {
    background: $table-bg-color_1;
    box-shadow: 0px 0px 10px $table-bg-color_3;
    text-align: center;
    margin: 0 auto 3em;
    padding: 2em;
    border-radius: 2em;

    h2 {
        font-size: 1.5em;
        font-weight: bold;

        @include breakpoint(medium down) {
            font-size: 2em;
        }
    }

    p {
        font-size: 1em;

        @include breakpoint(medium down) {
            font-size: 1.5em;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}
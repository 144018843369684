.core-value-sec {
    padding: 1em 4em;
    background: #deebf7;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include breakpoint(medium down) {
        font-size: 0.7em;
        padding: 2em 1em;
    }

    .core-values {
        margin: 0 15em;

        @include breakpoint(large down) {
            margin: 0 5em;
        }

        @include breakpoint(medium down) {
            margin: 0 10em;
        }

        @include breakpoint(small down) {
            margin: 0 1em;
            font-size: .94em;
        }
    }
}

.core-value-box {
    position: relative;
    padding: 0 0.6em;
    text-align: center;
    margin-bottom: 2em;

    img {
        height: 8em;
        display: inline-block;
        margin-bottom: 0.5em;
    }

    h5 {
        font-size: 1em;
        font-weight: 700;

        @include breakpoint(medium down) {
            font-size: 1.5em;
        }
    }

    p {
        font-size: 1em;

        @include breakpoint(medium down) {
            font-size: 1.5em;
        }
    }
}

.blue_super_bg {
    background: #deebf7;
    padding: 3em 1em;

    + .core-value-sec {
        margin-top: -3em;
    }

    @include breakpoint(medium down) {
        padding: 2em 1em;
        font-size: 0.7em;
    }

    h2, h3 {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;

        @include breakpoint(medium down) {
            font-size: 2em;
        }
    }

    p {
        font-size: 1em;

        @include breakpoint(medium down) {
            font-size: 1.5em;
        }
    }
}
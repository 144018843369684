$color_1: #fff;
$color_2: #000;

.plain-text-sec {
    padding: 3em 1em;
    background: #4472c4;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    text-align: center;

    @include breakpoint(medium down) {
        font-size: 0.7em;
        padding: 2em 1em;
    }

    h2 {
        color: $color_1;
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 1em;

        @include breakpoint(medium down) {
            font-size: 2em;
        }
    }

    p {
        color: $color_1;
        font-size: 1em;

        @include breakpoint(medium down) {
            font-size: 1.5em;
        }
    }
}

.pro-content {
    h2 {
        color: $color_2;
    }

    p {
        color: $color_2;

        &:last-child {
            margin-bottom: 0;
        }
    }

    img {
        width: 100%;
        margin-bottom: 0;

        @include breakpoint(medium down) {
            margin-bottom: 1em;
        }
    }

    > div {
        order: 0;
    }

    > div.pro-content-left, > div.pro-content-right {
        @include breakpoint(medium down) {
            order: 1;
            text-align: left;
        }
    }
}

.pro-content-left {
    text-align: left;
    padding-right: 1em;

    @include breakpoint(medium down) {
        padding-right: 0;
    }
}

.pro-content-right {
    text-align: right;
    padding-left: 1em;

    @include breakpoint(medium down) {
        padding-left: 0;
    }
}

.cta-bg-sec {
    padding: 2em 1em;
    background: #fff;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    text-align: center;

    @include breakpoint(medium down) {
        font-size: 0.7em;
        padding: 1em 1em;
    }
}

.cta-red-btn {
    display: inline-block;
    background: #da1a00;
    border-radius: 0.5em;
    padding: 0.5em 2.5em;
    color: $color_1;
    font-size: 1.3em;
    font-weight: 700;

    @include breakpoint(medium down) {
        padding: 0.5em 0.5em;
        font-size: 1.8em;
    }

    &:hover, &:focus {
        text-decoration: none;
        color: $color_1;
    }
}
$blue-dark: #4872ae;
$white: #fff;
.promotion-block {
    .hero-description {
        width: 100%;
        padding: 0;

        @include breakpoint (medium down) {
            padding: 1em;
        }

        .grid-container {
            flex: 0 0 100%;
        }

        h2 {
            font-size: 1.5em;
            line-height: 1.16;
            width: 100%;

            @include breakpoint (medium down) {
                font-size: 2em;
                padding-left: 0;
            }
        }

        p {
            font-size: 1em;
            width: 100%;
            line-height: 1.5;
            padding-left: 0;

            @include breakpoint (medium down) {
                font-size: 1.5em;
                padding-left: 0;
            }
        }
    }

    button {
        &.rmhc-primary-btn {
            font-size: 1.125em;
            font-weight: 800;
            color: $blue-dark;
            background-color: $white;
        }
    }

    .hero-description {
        // width: 69%;

        @include breakpoint (small down) {
            width: 100%;
            padding: 1em;
        }

        p.descrption {
            line-height: 1.5;
            width: 100%;
            font-size: 1.125em;
            word-break: break-word;
            padding-left: 2em;

            @include breakpoint (medium down) {
                padding-left: 0.7em;
            }

            @include breakpoint (small down) {
                font-size: 1em;
                line-height: 1.5;
            }
        }
    }

    h2 {
        font-size: 2em;
        padding-left: 2em;

        @include breakpoint (medium down) {
            padding-left: 0.7em;
        }
    }

    @include breakpoint (small down) {
        h2 {
            font-size: 2em;
            line-height: 1.16;
        }

        p {
            font-size: 1.5em;
            line-height: 1.2;
            width: 100%;
        }
    }

    @include breakpoint (large down) {
        img.promotion-left-img {
            object-fit: cover;
            object-position: right;
            height: 425px;
        }
    }

    @include breakpoint (medium down) {
        img.promotion-left-img {
            object-fit: cover;
            object-position: left;
            height: 350px;
        }
    }

    @include breakpoint (small down) {
        img.promotion-left-img {
            height: 680px;
        }
    }

    a {
        margin-left: 2em !important;

        @include breakpoint (medium down) {
            margin-left: 0.7em !important;
        }

        &.donate-btn {
            background-color: #da1a00;
            margin: 0;
            padding: 1em 1.25em;
            font-size: 1em;
            font-weight: 900;
            border-radius: 2px;
            line-height: normal;
            text-transform: uppercase;

            img {
                display: inline-block;
                vertical-align: top;
                margin-right: .625em;
            }
        }
    }


    @include breakpoint(medium down) {
        font-size: 0.7em;
    }
}

.promotion-block-right {
    text-align: right;

    .hero-description {
        width: 100%;
        padding-left: 0;
        left: auto;
        right: 0;
        padding-right: 0;

        &.hero-gradient {
            background: linear-gradient(to left,rgba(70,100,138,1) 0,rgba(70,100,138,0) 100%);

            @include breakpoint (medium down) {
                background: rgba(70,100,138,.8);
            }
        }

        .grid-container {
            flex: 0 0 100%;
        }

        @include breakpoint (medium down) {
            padding: 1em;
        }

        p {
            font-size: 1em;
            width: 100%;
            line-height: 1.5;
            padding-left: 2em;

            @include breakpoint (medium down) {
                font-size: 1.5em;
                padding-left: 0;
            }
        }

        @include breakpoint (small down) {
            h2 {
                font-size: 2em;
                line-height: 1.16;
                width: 100%;
            }

            p {
                font-size: 1.5em;
                line-height: 1.4em;
                width: 100%;
            }
        }

        a {
            margin-left: 2em !important;

            @include breakpoint (medium down) {
                margin-left: 0.7em !important;
            }

            &.donate-btn {
                background-color: #da1a00;
                margin: 0;
                padding: 1em 1.25em;
                font-size: 1em;
                font-weight: 900;
                border-radius: 2px;
                line-height: normal;
                text-transform: uppercase;

                img {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: .625em;
                }
            }
        }
    }

    @include breakpoint (large down) {
        img.promotion-left-img {
            height: 475px;
            object-fit: cover;
            object-position: left;
        }
    }

    @include breakpoint (large down) {
        img.promotion-right-img {
            height: 475px;
            object-fit: cover;
            object-position: left;
        }
    }

    @include breakpoint (medium down) {
        img.promotion-left-img {
            height: 350px;
            object-fit: cover;
            object-position: left;
        }
    }

    @include breakpoint (small down) {
        img.promotion-left-img {
            height: 570px;
        }
    }

    @include breakpoint (medium down) {
        font-size: 0.7em;
        text-align: left;

        img.promotion-right-img {
            height: 380px;
            object-fit: cover;
            object-position: right;
        }
    }

    @include breakpoint (small down) {
        img.promotion-right-img {
            height: 600px;
        }
    }
}
@media screen and (min-width: 1600px) {
    img.promotion-left-img, img.promotion-right-img {
        height: 400px;
        width: 100%;
    }
}
.profile-sec {
    padding: 3em 1em;
    background: #4371BB;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include breakpoint(medium down) {
        font-size: 0.7em;
        padding: 2em 1em;
    }

    .grid-x {
        justify-content: center;
    }

    .profile-title {
        color: #fff;
        font-size: 1.5em;
        font-weight: 400;
        text-align: center;
        margin-bottom: 1em;
    }
}

.profile-box {
    position: relative;
    padding: 0 0.6em;
    text-align: center;
    margin-bottom: 2em;

    a {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    img {
        height: 8em;
        display: inline-block;
        border-radius: 0 0 2em 0;
        box-shadow: 0px 0px 1px #0000008c;
        margin-bottom: 0.5em;
    }

    h5 {
        font-size: 1em;
        font-weight: 700;
        color: #fff;

        @include breakpoint(medium down) {
            font-size: 1.5em;
        }
    }

    p {
        font-size: 1em;
        color: #fff;

        @include breakpoint(medium down) {
            font-size: 1.5em;
        }
    }
}
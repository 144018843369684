$background-color_1: transparent;
.main-menu {
    .top-bar {
        padding: 0;
        background-color: $background-color_1;

        @include breakpoint(medium down) {
            flex-direction: column;
        }

        > div {
            @include breakpoint(medium down) {
                width: 100%;
            }
        }

        ul {
            background-color: $background-color_1;

            li a {
                color: #000;
                font-size: .9em;
                font-style: normal;
                font-weight: 600;
                border: 0;

                @include breakpoint(large down) {
                    font-size: .75em;
                }

                @include breakpoint(medium down) {
                    font-size: 1em;
                }
            }
        }

        .title-bar {
            background: #4472c4;
        }

        @include breakpoint(large down) {
            .top-bar-left {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}
.dropdown.menu {
    > li {
        > a {
            padding: 0.1rem 0.5rem;
        }
    }
}
footer {
    width: 100%;
    background-color: #404042;
    color: #fff;
    padding: 3em 1em;

    @include breakpoint(medium down) {
        font-size: 0.7em;
        padding: 2em 1em;
    }

    p {
        font-size: 1em;

        @include breakpoint(medium down) {
            font-size: 1.5em;
        }
    }

    p:last-child {
        margin: 0;
    }
}
$blue-super: #000;
$blue-dark: #000;
$gray-lighter: #000;
$white: #000;
.hero-block-componant{
  background-color:#fff;
  &.blue_super_bg{
    background-color:$blue-super;
  }
  &.super_blue_bg{
    background-color: $blue-dark;
  }
}
.rmhc-hero {
  position: relative;
  z-index: 0;
  @include breakpoint(medium down){
    margin-top:-0.5px;
  }

  img.hero-img {
    @include breakpoint (small down) {
      height: 430px;
      object-fit: cover;
      object-position: 80%;
    }
  }

  h1 {
    font-size: 1.8em;
    font-weight: bold;
    color: $white;
  }

  h2 {
    color: $white;
    font-size: 1.5em;
    font-weight: 700;
  }

  a {
    &.read-jordan {
      color: $white;
      font-size: 1.375em;
      font-weight: bold;
      font-style: normal;
      border-bottom: 1px solid #fff;
      padding-bottom: 8px;
      .arrow_icon{
        display: inline-block;
        padding-left:5px;
        &:after{
          content:'>';
          display: inline-block;        
        }
      }
     
      &:hover{
        color: $gray-lighter;
        border-bottom: 1px solid $gray-lighter;
      }
@include breakpoint (medium down){
  padding-bottom: 0px;
}
      hr {
        margin: 0;
        border: 1px solid $white;
      }
    }
  }


  button {

    &.slick-next,
    &.slick-prev {
      display: none !important;
    }
  }

  div {
    &.rectangle-text {
      width: 75%;
      background-color: $blue-dark;
      position: relative;
      height: 8.75em;
      margin: 0 auto;
      bottom: 5.875em;

      @include breakpoint(small down) {
        width: 96%;
        padding: 34px;
      }

      @include breakpoint(medium) {}

      p {
        color: $white;
        font-size: 1em;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: center;
        font-weight: 500;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 35%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-31%, -50%);

        @include breakpoint (small down) {
          font-size: 1.5em;
          line-height: 1.33;
          left: 27%;
          transform: translate(-20%, -50%);
        }
      }
    }

    &.rectangle-text1 {
      width: 35%;
      top: 50%;
      left: 17%;
      padding: 1em 5em;
      position: absolute;

      @include breakpoint (small down) {
        width: 100%;
        left: -4.063em;
        top: 54%;
      }

      p {
        color: $white;
        font-size: 1em;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
      }
    }
  }
}

.hero-description {
  position: absolute;
  width:100%;
  left: 0;
  top: 0;
  height: 100%;  
  box-sizing: border-box;
  padding:0;
.grid-container{
  flex:0 0 100%;
}
  @include breakpoint(medium down) {
    //padding: 0 2em;
    width: 100%;
  }
&.hero-gradient{
  background: linear-gradient(to right,rgba(70,100,138,1) 0,rgba(70,100,138,0) 100%);
  @include breakpoint (medium down){
      background: rgba(70,100,138,.8);
  }
}
  .descrption {
    color: $white;
    font-size: 1.375em;
    font-weight: 600;
    line-height: 1.5;    
    margin: 0;

    @include breakpoint(small down) {
      font-size: 1.375em;
      width: 100%;
    }
  }
}

.hero-bottom-text {
  margin-top: -6.875em;
  position: relative;


  @include breakpoint (medium down) {
    margin-top: -1.5em;
  }

  @include breakpoint(small down) {
    margin-top: -1.688em;
  }

  .rectangle-text2 {
    background: $blue-dark;
    padding: 2.5em;
    width: 89%;

    @include breakpoint(small down) {
      padding: 1.25em;
      width: 100%;
    }
    p {
      font-size: 1em;
      font-weight: 700;
      font-family: 'Raleway', sans-serif;
      color: #fff;
      line-height: 1.36;
      margin: 0;

      @include breakpoint(small down) {
        font-size: 1.5em;
      }
    }
  }
}

.image-map-container {
    position: relative;
    padding: 0 0 0.5em;

    @include breakpoint(medium down) {
        font-size: 0.7em;
    }

    h2, h3 {
        font-size: 1.5em;
        font-weight: 700;
        text-align: center;
        margin: 0;

        @include breakpoint(medium down) {
            font-size: 2em;
        }
    }

    a.image-map-area-type1 {
        position: absolute;
        display: inline-block;
        width: 28%;
        height: 20%;
    }

    a.image-map-area-type2 {
        position: absolute;
        display: inline-block;
        width: 50%;
        height: 6%;
    }
}
.impact-strategy-link {
    display: block;
    text-align: center;
    font-size: 1.2em;
    color: #4872ae;
    margin-bottom: 1em;

    @include breakpoint(medium down) {
        font-size: 1em;
        margin-bottom: 0.7em;
    }
}
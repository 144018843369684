$bg-color_1: #4472c4;
$color_1: #fff;

.title-sec, .icon-title-sec {
    padding: 0.5em 1em;
    background: $bg-color_1;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include breakpoint(medium down) {
        padding: 2em 1em;
    }

    .title-box {
        display: flex;
        align-items: center;
        justify-content: start;
        height: 5em;
        h1 {
            color: $color_1;
            font-size: 1.8em;
            font-weight: bold;
            margin: 0;

            @include breakpoint(medium down) {
                font-size: 1.5em;
            }
        }
    }
}

.icon-title-sec {
    .title-box {
        justify-content: center;

        img {
            height: 5em;
            margin-right: 1em;
        }
    }
}
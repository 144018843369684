$color_1: #4872ae;
$color_2: #000;
$color_3: #fff;
$color_4: #f1f1f1;

.strategy-framework-sec {
    padding: 2em 1em;
    background: $color_4;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    h2 {
        color: $color_1;
        font-size: 1.5em;
        font-weight: 700;
        margin-bottom: 1em;
        text-align: center;

        @include breakpoint(medium down) {
            font-size: 2em;
        }
    }

    h3 {
        color: $color_2;
        font-size: 1.2em;
        font-weight: 500;

        @include breakpoint(medium down) {
            font-size: 1.7em;
        }
    }

    @include breakpoint(medium down) {
        font-size: 0.7em;
        padding: 2em 1em;
    }
}

.aim-box {
    background: #e46e00;
    color: $color_2;
    padding: 1em;
    text-align: center;
    border: 2px solid #fff;
    margin-bottom: 1em;

    &:first-child {
        border-radius: 3em 0 0 3em;
        background: #4872ae;
        color: $color_3;

        @include breakpoint(small down) {
            border-radius: 3em;
        }

        h3 {
            color: $color_3;
        }
    }

    &:last-child {
        border-radius: 0 3em 3em 0;
        background: #ffc829;
        color: $color_2;

        @include breakpoint(small down) {
            border-radius: 3em;
        }
    }

    @include breakpoint(small down) {
        border-radius: 3em;
    }

    h3 {
        color: $color_2;
        font-weight: 700;
    }

    p {
        font-size: 1em;
        font-weight: 600;
        margin-bottom: 0;

        @include breakpoint(medium down) {
            font-size: 1.5em;
        }
    }
}

.strategic-priorities-sec {
    background: #dfedf8;
    padding: 1em;
    border-radius: 1em;
    text-align: center;
    margin-bottom: 1em;

    > div {
        justify-content: center;
        align-items: center;

        > .columns {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }

    h3 {
        color: $color_1;
    }
}

.strategic-box {
    background: #fff;
    margin-bottom: 1em;
    padding: 1em;
    border-radius: 1em;

    h4 {
        color: $color_1;
        text-align: left;
        font-size: 1.1em;
        font-weight: 700;

        @include breakpoint(medium down) {
            font-size: 1.6em;
        }

        img {
            height: 2em;
            margin-right: 0.2em;
            margin-top: -0.2em;
        }
    }

    ul {
        text-align: left;
        padding-left: 2em;

        @include breakpoint(medium down) {
            padding-left: 0;
        }

        li {
            font-size: 1em;
            font-weight: 600;

            @include breakpoint(medium down) {
                font-size: 1.5em;
            }
        }
    }
}

.strategic-values {
    h4 {
        color: $color_1;
        text-align: left;
        font-size: 1.1em;
        font-weight: 700;
        text-align: center;
        color: $color_2;

        @include breakpoint(medium down) {
            font-size: 1.6em;
        }
    }

    background: #fff;
    padding: 2em;
    border-radius: 50%;
    margin-bottom: 1em;

    @include breakpoint(medium down) {
        padding: 2.5em;
    }

    p {
        font-size: 1em;
        font-weight: 600;
        margin-bottom: 0.5em;

        @include breakpoint(medium down) {
            font-size: 1.5em;
        }
    }
}

.read-more {
    background: #DA1A00;
    color: $color_3;
    display: inline-block;
    padding: 0.2em 1em;
    border-radius: 1em;
    font-size: 0.9em;
    font-weight: 600;

    @include breakpoint(medium down) {
        font-size: 1.4em;
    }

    &:hover {
        color: $color_4;
    }

    &:focus {
        color: $color_4;
    }
}

.enablers-sec {
    background: #ffc829;
    width: 90%;
    margin: 0 auto 1em;
    text-align: center;
    padding: 1em 2em;
    border-radius: 3em;
}

.foundations-sec {
    background: #4872ae;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 1em 2em;
    border-radius: 5em;

    h3 {
        color: $color_3;
    }
}
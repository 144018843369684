$table-bg-color_1: #4472c4;
$table-bg-color_2: #d2deef;
$table-color_1: #5c9cd5;

.yellow-table {
    padding: 4em 1em;
    background: $table-bg-color_1;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    h2, h3 {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin-bottom: 1em;

        @include breakpoint(medium down) {
            font-size: 2em;
        }
    }

    @include breakpoint(medium down) {
        font-size: 0.7em;
        padding: 2em 1em;
    }

    tbody {
        tr {
            &:nth-child(even) {
                background-color: $table-bg-color_2;
            }
        }

        td {
            padding: 1.5em 1.625em 1.625em;
            border: 1.5px solid $table-color_1;
            font-size: 1em;

            @include breakpoint(medium down) {
                padding: 0.5em 0.625em 0.625em;
                font-size: 1.5em;
            }
        }

        th {
            padding: 1.5em 1.625em 1.625em;
            border: 1.5px solid $table-color_1;
            width: 35%;
            text-align: start;
            font-size: 1em;

            @include breakpoint(medium down) {
                padding: 0.5em 0.625em 0.625em;
                font-size: 1.5em;
            }
        }
    }
}
//Vendor
@import 'RMHC-foundation';

//Global
html, * {
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
}
h1, h2, h3, h4, h5, h6, p {
    font-family: 'Raleway', sans-serif;
}
a {
    font-family: 'Raleway', sans-serif;
    color: #000;
}
a:focus, a:hover {
    color: #4472c4;
}
//Components
@import './frmhc-components/_header-menu';
@import './frmhc-components/_yellow-table';
@import './frmhc-components/_yellow-box';
@import './frmhc-components/_title-sec';
@import './frmhc-components/_cta-sec';
@import './frmhc-components/_profile-sec';
@import './frmhc-components/_plain-text-sec';
@import './frmhc-components/_core-value-sec';
@import './frmhc-components/_image-map';
@import './frmhc-components/_Impact-strategy';
////////////////
@import './frmhc-components/_promotion.scss';
@import './frmhc-components/_hero.scss';